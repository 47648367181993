/* eslint-disable no-unused-vars */
import React from 'react'
import abimg from '../../images/about/img-6.png'
import shape from '../../images/about/frame.png'
import { Link } from 'react-router-dom'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const About5 = (props) => {
    return (
        <section className="wpo-about-section-s5 section-padding">
            <div className="container">
                <div className="wpo-about-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-img">
                                <img src={abimg} alt="" />
                                <div className="frame"><img src={shape} alt="" /></div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-text">
                                <div className="wpo-section-title">
                                    <small>学習中の様子</small>
                                    <h2>参加自由 </h2>
                                    <li>※入退室は自由ですが必ずお声掛けをお願いします</li>
                                    
                                </div>
                                {/* <p>テキストなどを使用したり少人数でのレッスンをしたり、可能であればマンツーマンの授業もします。特にこの教室が他の教室と違うところは、テーブルを囲んでみんなで会話を中心に勉強をすることです。レベルに合わせて会話をし、会話が難しい人は分けて少人数で個別に教えたりもしています。また、交流会を開いたり、一緒にでかけたり、花見のシーズンには近くの公園で花見を楽しむこともあります。
 
 ボランティアの先生が少ないときもあり、思うように学習ができないこともありますが、教室の時間外でも可能なかぎりSNSや電話などでも質問をうけます。もちろん勉強以外のことでも可能なかぎり相談にのります。日本語能力試験のための勉強にも力をかします！</p>
                                 */}
                                <ul>
                                
                                <li>連絡方法：鈴木　先生（日本語対応）</li>
                              <li>　　　　　suzuya@tea.ocn.ne.jp(✉)</li>
                              <li>　　　　　090-3334-3566(📞)</li>
                               <li>　　　　　劉さん（中国語対応）</li>
                              <li>　　　　　constljx@gmail.com(✉)</li>
                              
                              <li>担当者: 鈴木　弥</li>
                              <li>住所  :にこわ新小岩 3階　東京都葛飾区西新小岩4-33-2</li>
                                </ul>
                                <Link onClick={ClickHandler} to="/home" className="theme-btn-s2">詳細はこちら</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About5;