/* eslint-disable no-unused-vars */
import React, {useState,Fragment} from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero5 from '../../components/hero5/Hero5';
import Features2 from '../../components/Features2/Features2';
import About5 from '../../components/about5/about5';
import FunFact2 from '../../components/FunFact2/FunFact2';
import CategorySection from '../../components/CategorySection/CategorySection';
import CourseSectionS2 from '../../components/CourseSectionS2/CourseSectionS2';
import TeamSection from '../../components/TeamSection/TeamSection';
import BlogSection from '../../components/BlogSection/BlogSection';
import Newslatter2 from '../../components/Newslatter2/Newslatter2';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import '../../css/FloatingWindow.css'; 
const HomePage5 = ({
    openButtonText = 'ボランティアの先生を募集します！詳細はこちら→',  // 自定义默认打开按钮文字
    closeButtonText = '閉じる', // 自定义默认关闭按钮文字
    windowCloseButtonText = '閉じる'   // 自定义关闭悬浮窗的按钮文字
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleWindow = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-2'} />
            <Hero5 />
            <About5 />
            <Footer />
            <Scrollbar />

            {/* 悬浮窗按钮 */}
            <div className="floating-button" onClick={toggleWindow}>
                {isOpen ? closeButtonText : openButtonText}
            </div>

            {/* 悬浮窗内容 */}
            {isOpen && (
                <div className="floating-window">
                    <div className="floating-window-header">
                        <h1 className="banner">ボランティアの先生を募集します!</h1>
                        <button className="close-button" onClick={toggleWindow}>
                            {windowCloseButtonText}
                        </button>
                    </div>
                    <p>　　年齢は問いません。壮齢、シニアのボランティアの方が中心ですが、18歳の女性ボランティア先生もいます。<br/>
                       　　資格も必要ありません。外国の方と日本語を通じて交流を持ちたいと意欲がある方なら誰でもできます。文法など細かいことを忘れてしまっていても大丈夫です。<br/>
                       　　なんと言っても私達は日本語のプロですからそれぞれのボランティアの方が自分の思い通りに自由に学習者に教えてください！<br/>
                       　　困った時は他のボランティアの先生がお手伝いしますし、最初はボランティア先生の補佐的について学習を進めていただいても構いません。<br/>
                       　　是非私達と一緒に日本語を教えながら交流を深めていきましょう！
                       ご希望の方は代表の鈴木までメールで連絡してください。</p>
                </div>
            )}
        </Fragment>
    );
};

export default HomePage5;
