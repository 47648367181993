import cimg1 from '../images/category/img-1.jpg'
import cimg2 from '../images/category/img-2.jpg'
import cimg3 from '../images/category/img-3.jpg'
import cimg4 from '../images/category/img-4.jpg'
import cimg5 from '../images/category/img-5.jpg'
import cimg6 from '../images/category/img-6.jpg'



const CoursesCategory = [
   {
      Id: '1',
      cImg: cimg1,
      thumb: 'flaticon-user-experience',
      title: 'うなぎ',
      slug: 'うなぎ',
      description: '値段が高い',
   },
   {
      Id: '2',
      cImg: cimg2,
      thumb: 'flaticon-megaphone',
      title: 'すいとん',
      slug: 'すいとん',
      description: 'すいとんとは、小麦粉の生地をだし汁にひと口大の大きさに形成して煮込む料理です。 '
   },
   {
      Id: '3',
      cImg: cimg3,
      thumb: 'flaticon-code',
      title: 'ちらし寿司',
      slug: 'Development',
      description: '具材と酢飯が準備できたら、器に平たく酢飯を盛り付け、順に具材をのせていきます。'
   },
   {
      Id: '4',
      cImg: cimg4,
      thumb: 'flaticon-knowledge',
      title: '餃子',
      slug: '餃子',
      description: 'キムチ餃子、豚肉ニラ餃子、鶏肉キノコ餃子、エビと卵餃子'
   },
   {
      Id: '5',
      cImg: cimg5,
      thumb: 'flaticon-video-lesson',
      title: '銀杏',
      slug: '銀杏',
      description: '銀杏の調理方法はさまざまですが、家庭で調理するなら電子レンジと紙封筒を使った方法をおすすめ。テクニックは不要、短時間でムラなく加熱できます。'
   },
   {
      Id: '6',
      cImg: cimg6,
      thumb: 'flaticon-paint-palette',
      title: 'カレー ',
      slug: 'カレー',
      description: 'カレーのように長時間煮込む料理には、厚手の鍋が向いています。厚手の鍋は熱の伝わり方が間接的で平均しており保温性もよいので、途中で材料を加えても温度変化がゆるやかになります。'
   },

]

export default CoursesCategory;