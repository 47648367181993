import eimg1 from '../images/event/img-1.jpg'
import eimg2 from '../images/event/img-2.jpg'
import eimg3 from '../images/event/img-3.jpg'
import eimg4 from '../images/event/img-4.jpg'
import eimg5 from '../images/event/img-5.jpg'
import eimg6 from '../images/event/img-6.jpg'

import eSimg1 from '../images/event/single/img-1.jpg'
import eSimg2 from '../images/event/single/img-2.jpg'
import eSimg3 from '../images/event/single/img-3.jpg'



const Events = [
    {
       Id:'1',
       eImg:eimg1,
       eSimg:eSimg1,
       thumb:'Conference',   
       title:'高尾山登る会',  
       slug:'高尾山登る会',
       month:'12',
       date:'Nov',
       time:'2023',
       location:'高尾山',
       progress:'90',
       raised:'2000.00',
       goal:'3000.00',
    },
    {
       Id:'2',
       eImg:eimg2,
       eSimg:eSimg2,
       thumb:'Entertainment',    
       title:'葛西臨海公園BBQ', 
       slug:'葛西臨海公園BBQ',
       month:'26',
       date:'Aug',
       time:'2023',
       location:'葛西臨海公園',
       progress:'80',
       raised:'3000.00',
       goal:'4000.00',
    },
    {
      Id:'3',
      eImg:eimg3,
      eSimg:eSimg3,
      thumb:'Conference',   
      title:'葛飾区花火大会',  
      slug:'葛飾区花火大会',
      month:'25',
      date:'Jul',
      time:'2023',
      location:'葛飾区',
      progress:'95',
      raised:'4000.00',
      goal:'5000.00',
   },
   {
      Id:'4',
      eImg:eimg4,
      eSimg:eSimg1,
      thumb:'Conference',   
      title:'新小岩公園夜桜',  
      slug:'新小岩公園夜桜',
      month:'31',
      date:'Mar',
      time:'2023',
      location:'新小岩公園',
      progress:'90',
      raised:'2000.00',
      goal:'3000.00',
   },
   {
      Id:'5',
      eImg:eimg5,
      eSimg:eSimg2,
      thumb:'Conference',   
      title:'Sky Duck',  
      slug:'Sky-Duck',
      month:'20',
      date:'Nov',
      time:'2022',
      location:'お台場',
      progress:'90',
      raised:'2000.00',
      goal:'3000.00',
   },
   {
      Id:'6',
      eImg:eimg6,
      eSimg:eSimg3,
      thumb:'Conference',   
      title:'横浜中華街の忘年会',  
      slug:'横浜中華街の忘年会',
      month:'10',
      date:'Dec',
      time:'2022',
      location:'横浜',
      progress:'90',
      raised:'2000.00',
      goal:'3000.00',
   }
    
]

export default Events;