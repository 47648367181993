import React, { Fragment, useState } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { NavLink } from "react-router-dom";
import './style.css';

const menus = [
    {
        id: 1,
        title: 'ホーム',
        link: '/home-5',
        // submenu: [
        //     {
        //         id: 11,
        //         title: 'Home style 1',
        //         link: '/home-1'
        //     },
        //     {
        //         id: 12,
        //         title: 'Home style 2',
        //         link: '/home-2'
        //     },
        //     {
        //         id: 13,
        //         title: 'Home style 3',
        //         link: '/home-3'
        //     },
        //     {
        //         id: 14,
        //         title: 'Home style 4',
        //         link: '/home-4'
        //     },
        //     {
        //         id: 15,
        //         title: 'Home style 5',
        //         link: '/home-5'
        //     },
        // ]
    },

   

    {
        id: 6,
        title: 'ボランティア',
        link: '',
        submenu: [
            {
                id: 61,
                title: '日本語先生',
                link: '/teacher',
            },
            {
                id: 61,
                title: 'ボランティア募集',
                link: '/become-teacher',
            }
            // {
            //     id: 61,
            //     title: 'Courses Style 3',
            //     link: '/course-3',
            // },
            // {
            //     id: 62,
            //     title: 'Course Single',
            //     link: '/course-single/Learn-WordPress-&-Elementor-for-Beginners'
            // }
        ]
    },
    {
        id: 2,
        title: '日本語教室',
        link: '/home',
    },
    {
        id: 3,
        title: '料理教室',
        link: '/',
        submenu: [
            {
                id: 31,
                title: '次の料理教室（未定）',
                link: ''
            },
            {
                id: 2,
                title: '過去の料理教室',
                link: '/home-3'
            },
            // {
            //     id: 322,
            //     title: 'Testimonial',
            //     link: '/testimonial'
            // },
            // {
            //     id: 385,
            //     title: 'Teachers',
            //     link: '/teacher',
            // },
            // {
            //     id: 386,
            //     title: 'Team Single',
            //     link: '/team-single/Courtney-Henry'
            // },
            // {
            //     id: 388,
            //     title: 'Become Teacher',
            //     link: '/become-teacher'
            // },
            // {
            //     id: 371,
            //     title: 'Shop',
            //     link: '/shop',
            // },
            // {
            //     id: 375,
            //     title: 'Shop Single',
            //     link: '/product-single/Newspaper'
            // },
            // {
            //     id: 376,
            //     title: 'Cart',
            //     link: '/cart'
            // },
            // {
            //     id: 377,
            //     title: 'Checkout',
            //     link: '/checkout'
            // },
            // {
            //     id: 33,
            //     title: 'FAQ',
            //     link: '/faq'
            // },
            // {
            //     id: 34,
            //     title: '404 Error',
            //     link: '/404'
            // },
            // {
            //     id: 35,
            //     title: 'Login',
            //     link: '/login'
            // },
            // {
            //     id: 36,
            //     title: 'Register',
            //     link: '/register'
            // },
        ]
    },

    {
        id: 5,
        title: 'イベント',
        link: '',
        submenu: [
            // {
            //     id: 51,
            //     title: '最新イベント(開発中)',
            //     link: ''
            // },
            {
                id: 52,
                title: 'イベント履歴',
                link: '/event'
            }
            // {
            //     id: 53,
            //     title: 'Blog full width',
            //     link: '/blog-fullwidth'
            // },
            // {
            //     id: 54,
            //     title: 'Blog single',
            //     link: '/blog-single/Become-a-great-WordPress-&-PHP-developer.'
            // },
            // {
            //     id: 55,
            //     title: 'Blog single Left sidebar',
            //     link: '/blog-single-left-sidebar/Become-a-great-WordPress-&-PHP-developer.'
            // },
            // {
            //     id: 56,
            //     title: 'Blog single Left sidebar',
            //     link: '/blog-single-fullwidth/Become-a-great-WordPress-&-PHP-developer.'
            // },
        ]
    },
    {
        id: 88,
        title: 'お問い合わせ',
        link: '/contact',
    }


]


const MobileMenu = () => {
    
    const [openId, setOpenId] = useState(0);
    const [menuActive, setMenuState] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div>
            <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
                <div className="menu-close">
                    <div className="clox" onClick={() => setMenuState(!menuActive)}><i className="ti-close"></i></div>
                </div>

                <ul className="responsivemenu">
                    {menus.map((item, mn) => {
                        
                        return (
                            <ListItem className={item.id === openId ? 'active' : null} key={mn}>
                                {item.submenu ?
                                    <Fragment>
                                        <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>{item.title}
                                            <i className={item.id === openId ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i>
                                        </p>
                                        <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                                            <List className="subMenu">
                                                <Fragment>
                                                    {item.submenu.map((submenu, i) => {
                                                        return (
                                                            <ListItem key={i}>
                                                                <NavLink onClick={ClickHandler} className="active"
                                                               
                                                                    to={submenu.link}>{submenu.title}</NavLink>
                                                            </ListItem>
                                                        )
                                                    })}
                                                </Fragment>
                                            </List>
                                        </Collapse>
                                    </Fragment>
                                    : <NavLink className="active"
                                        to={item.link}>{item.title}</NavLink>
                                }
                            </ListItem>
                        )
                    })}
                </ul>

            </div>

            <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
                <button type="button" className="navbar-toggler open-btn">
                    <span className="icon-bar first-angle"></span>
                    <span className="icon-bar middle-angle"></span>
                    <span className="icon-bar last-angle"></span>
                </button>
            </div>
        </div>
    )
}

export default MobileMenu;