import timg1 from '../images/team/1.jpg'
import timg2 from '../images/team/2.jpg'
import timg3 from '../images/team/3.jpg'
import timg4 from '../images/team/4.jpg'
import timg5 from '../images/team/5.jpg'
import timg6 from '../images/team/6.jpg'
import timg7 from '../images/team/7.jpg'
import timg8 from '../images/team/8.jpg'


const Teams = [
    {
        Id: '1',
        tImg:timg1,
        slug:'suzukimitsu',
        name:'鈴木　mitsu',
        title:'先生',
    },
    {
        Id: '2',
        tImg:timg2,
        slug:'morayamayuriko',
        name:'村山　yuriko',
        title:'先生',
    },
    {
        Id: '3',
        tImg:timg3,
        slug:'maedakyoko',
        name:'前田　kyoko',
        title:'先生',
    },
    {
        Id: '4',
        tImg:timg4,
        slug:'yagimami',
        name:'八木　mami',
        title:'先生',
    },
    {
        Id: '5',
        tImg:timg5,
        slug:'hinosusumu',
        name:'日野　susumu',
        title:'先生',
    },
    {
        Id: '6',
        tImg:timg6,
        slug:'abekenta',
        name:'阿部　kenta',
        title:'先生',
    },
    {
        Id: '7',
        tImg:timg7,
        slug:'yoshinosachiko',
        name:'吉野　sachiko',
        title:'先生',
    },
    {
        Id: '8',
        tImg:timg8,
        slug:'shinadayuna',
        name:'品田　yuna',
        title:'先生',
    },
]

export default Teams;