/* eslint-disable no-unused-vars */
import React from 'react'
 import abimg from '../../images/about/img-3.png'
import shape from '../../images/about/shape.png'
import { Link } from 'react-router-dom'


const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const About2 = (props) => {
    return (
        <section className="wpo-about-section-s2 section-padding">
             <div className="container">
                <div className="wpo-about-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-img">
                                <img src={abimg} alt="" />
                                <div className="back-shape">
                                    <img src={shape} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-text">
                                <div className="wpo-section-title">
                                    <small>料理教室</small>
                                    <h2>「ボラボラ」料理教室餃子の会
                                    </h2>
                                </div>
                                <p>歴史は古く、中国大陸の春秋時代の紀元前6世紀頃に現在の山東省で誕生したとされている。遺跡から、当時餃子が食べられていた痕跡が見つかっている。敦煌の唐代の墳墓では、副葬品として壺に入った餃子が乾燥状態で発見されている</p>
                                <p>日本の餃子は、日本で独自に変化したもので、焼き餃子が主流である（中国では水餃子 ＝ 茹でたものが主流）。用いる具材、調理法も中国で主流のものと異なる。

かつては皮も含めて家庭で材料から作ることが多かったが、市販の皮をスーパーマーケットなどで買って具を作って包むという方法をとる家庭も増えた。いつ頃からかわからないが具を皮に包んだ状態の冷凍食品・チルド品も多くの種類が出回っている。各種飲食店のサイドメニューとしても広く扱われていて、焼き上がった餃子や調理前の生の餃子を持ち帰り用に販売している店もある。</p>
                                {/* <Link onClick={ClickHandler} to="/about" className="theme-btn-s2">Learn More About Us</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default About2;