/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import hero1 from '../../images/slider/3.png'


const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const Hero3 = () => {

    useEffect(() => {
        const changingElement = document.getElementById('changing');

        if (changingElement) {
            const startChangingText = (texts, interval) => {
                let index = 0;
                setInterval(() => {
                    changingElement.textContent = texts[index];
                    index = (index + 1) % texts.length;
                }, interval);
            };

            startChangingText(['すいとん', 'ちらし寿司', 'カレー', 'うなぎ', 'どじょう'], 3000);
        }
    }, []);

    return (
        <section className="static-hero-s3">
            <div className="hero-container">
                <div className="hero-inner">
                    <div className="container-fluid">
                        <div className="hero-content">
                            <div data-swiper-parallax="300" className="slide-title">
                                <h2>料理教室
                                <p>一緒に作りましょう</p>
                                    <span>
                                        <small id='changing'>寿司</small>
                                        <i className="s1"></i>
                                        <i className="s2"></i>
                                        <i className="s3"></i>
                                        <i className="s4"></i>
                                    </span>
                                </h2>
                            </div>
                            <div data-swiper-parallax="400" className="slide-text">
                                <p>毎月の最初の金曜日、料理教室でみんなと一緒に料理を作ります、そして食べながら話しします。</p>
                            </div>
                            <div className="clearfix"></div>
                            <div data-swiper-parallax="500" className="slide-btns">
                                {/* <Link onClick={ClickHandler} to="/about" className="theme-btn">Get Started</Link> */}
                            </div>
                            <div className="student-pic">
                                <img src={hero1} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero3;