/* eslint-disable no-unused-vars */
import React from "react";
import wImg from '../../images/choose2.jpg'
import VideoModal from "../ModalVideo/VideoModal";

const ChooseSectionS2 = (props) => {
    return (
        <section className="wpo-choose-section-s2 section-padding">
            <div className="container">
                {/* <div className="right-img">
                    <img src={wImg} alt="" />
                     <VideoModal /> 
                </div> */}
                <div className="wpo-choose-wrap">
                    <div className="wpo-choose-grids clearfix">
                        <div className="grid">
                            {/* <div className="icon">
                                <i className="fi flaticon-training"></i>
                            </div> */}
                            <div className="info">
                                <h3>主催者</h3>
                                <p>石黒　先生</p>
                            </div>
                        </div>
                        <div className="grid">
                            {/* <div className="icon">
                                <i className="fi flaticon-support"></i>
                            </div> */}
                            <div className="info">
                                <h3>開催時間</h3>
                                <p>2024-02-02 金曜日</p>
                                　<p>１９：００－２０：４５</p>
                            </div>
                        </div>
                        <div className="grid">
                            {/* <div className="icon">
                                <i className="fi flaticon-e-learning"></i>
                            </div> */}
                            <div className="info">
                                <h3>参加方法</h3>
                                <p>来ればいい</p>
                            </div>
                        </div>
                        <div className="grid">
                            {/* <div className="icon">
                                <i className="fi flaticon-medal-1"></i>
                            </div> */}
                            <div className="info">
                                <h3>お問い合わせ</h3>
                                <p>詳細はお問い合わせページに記載されています</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ChooseSectionS2;