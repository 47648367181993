/* eslint-disable no-unused-vars */
import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import TeamSection from '../../components/TeamSection/TeamSection';
import Footer from '../../components/footer/Footer';
import Newslatter from '../../components/Newslatter/Newslatter';
import EventSection from '../../components/EventSection/EventSection';

const TeamPage =() => {
    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={'イベント履歴'} pagesub={'event'}/> 
            
            <Newslatter/>
            <EventSection/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default TeamPage;
